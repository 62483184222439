<template>
  <div class="flow_contanter">
    <headerTab></headerTab>
    <!-- 流程表 -->
    <div class="flow_content">
      <div class="lookMore" @click="lookMore" v-if="(messageList.findIndex(e=>{
        return e.itemShow==false
      }))!=-1">查看更多流程>></div>
      <div class="lookMore" @click="lookMore(1)" v-else>收起流程>></div>
      <div class="flow_list" v-for="(item,index) in messageList" :key="index" v-show="item.itemShow">
        <div class="flow_header">
          <div class="flow_box" :class="index<step?'bs_line':'bg_line'"></div>
          <div class="line_flow" v-if="index!=9" :class="index<step?'bs_line':'bg_line'"></div>
        </div>

        <div v-if="index==0">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade?'color:#FF5E5E':'color:#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.audit_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.audit_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30" v-if="!tableData.trade.audit_state">
            {{item.applyText}}
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.audit_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.audit_state>=1">{{tableData.trade.audit_at}}</div>
        </div>

        <div v-if="index==1">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.audit_state==1?'color:#FF5E5E':'color:#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.sales_info_state>=1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.sales_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.sales_info_state==0">
            {{item.applyText}}
            <div class="check" v-if="index==1" @click="checkBtn">核对资料<img src="../../assets/rightIcon4.png" /></div>
          </div>
          <div v-if="tableData.trade">
            <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.sales_info_state>=1">{{item.applySuccessText}}<div class="check" v-if="index==1" @click="checkBtn">重新核对资料<img src="../../assets/rightIcon4.png" /></div>
            </div>
            <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.sales_info_state>=1">{{tableData.trade.sales_info_at}}</div>
          </div>

        </div>

        <div v-if="index==2">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.sales_info_state==1?'color:#FF5E5E':'color:#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.payment_state==1&&tableData.trade.sales_info_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.payment_state==2&&tableData.trade.sales_info_state" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.sales_info_state==1&&tableData.trade.payment_state==0&&tableData.trade.sales_info_state==1">
            {{item.applyText}}
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.payment_state>=1&&tableData.trade.sales_info_state==1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.payment_state>=1&&tableData.trade.sales_info_state==1">{{tableData.trade.payment_info_at}}</div>
        </div>

        <div v-if="index==3">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.payment_state>=1&&tableData.trade.sales_info_state==1?'color:#FF5E5E':'#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.return_state>=1&&tableData.trade.sales_shipments.length==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.return_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:block" v-if="tableData.trade.return_state==0&&tableData.trade.payment_state==1&&tableData.trade.sales_info_state==1">
            <div>请回寄大于等于转让数额的工分赠与合同</div>
            <div>当前转让工分数：{{tableData.shares}} <span @click="returnContract" style="color:#0F87FF">点击邮寄></span></div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.return_state>=1&&tableData.trade.sales_shipments.length==1">{{item.applySuccessText}}
              <span v-if="tableData.trade.sales_shipments.length==1" @click="logistics1(0)">查看详情></span>
          </div>
          <div class="flow_text1" v-if="tableData.trade.sales_shipments.length==0&&tableData.trade.payment_state>=1&&tableData.trade.sales_info_state==1"><span @click="returnContract" style="color:#0F87FF">点击邮寄></span></div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.return_state>=1&&tableData.trade.sales_shipments.length==1">{{tableData.trade.return_at}}</div>
        </div>

        <div v-if="index==4">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.return_state>=1?'color:#FF5E5E':'#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.contract_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.contract_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:block" v-if="tableData.trade.return_state>=1&&tableData.trade.contract_state==0">
            {{item.applyText}}
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.contract_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.contract_state>=1">{{tableData.trade.contract_info_at}}</div>
        </div>

        <div v-if="index==5">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.contract_state>=1?'color:#FF5E5E':'#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.remit_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.remit_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:block" v-if="tableData.trade.contract_state>=1&&tableData.trade.remit_state==0">
            {{item.applyText}}<span style="color:#0F87FF" @click="submitPay(1,1)">查看订单> </span>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.remit_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.remit_state>=1">{{tableData.trade.contract_info_at}}</div>
        </div>

        <div v-if="index==6">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.remit_state>=1||tableData.trade.receipt_state==3?'color:#FF5E5E':'#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.receipt_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.receipt_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.trade.remit_state>=1&&tableData.trade.receipt_state==0">
            {{item.applyText}}<span style="color:#0F87FF" @click="submitPay(2)">查看打款记录> </span>
          </div>
          <div class="flow_text1" style="color:#FF3E46;display:block" v-if="tableData.trade.receipt_state==2">
            收款异常，等待平台处理
          </div>
          <div class="flow_text1" style="color:#FF3E46;display:block" v-if="tableData.trade.receipt_state==3">
            异常已核实，等待受让方处理款项！
          </div>
          <div class="item_btn" v-if="tableData.trade.receipt_state==0&&tableData.trade.remit_state>=1">
            <div @click="submitPay(1)">收款异常</div>
            <div style="background:#07C160" @click="submitPay">确认收款</div>
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.receipt_state==1">{{item.applySuccessText}}</div>
          <div class="flow_text1" :style="tableData.trade.receipt_state==2?'color:#FF3E46':'color:#07C160'" v-if="tableData.trade.receipt_state>0">{{tableData.trade.receipt_at}}</div>
        </div>

        <div v-if="index==7">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.receipt_state==1?'color:#FF5E5E':'#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.sales_pay_at" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.receipt_state==1&&!settingData.shares_trade_shipping_fee" src="../../assets/successIcon2.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:flex" v-if="tableData.trade.receipt_state==1&&settingData.shares_trade_shipping_fee">
            <div style="display:flex">
              <div style="color:#07C160">运费：<span style="color:#07C160">{{tableData.sales_pay_admin_id?tableData.sales_pay_amount:settingData.shares_trade_shipping_fee}}</span><span style="color:#0F87FF;margin-left:10px" v-if="tableData.trade_pay_at">已支付</span></div>
              <div class="check" v-if="!tableData.trade.sales_pay_at" @click="payBtn(3)">点击支付<img src="../../assets/rightIcon4.png" /></div>
            </div>
          </div>
          <div v-if="tableData.trade.receipt_state==1&&!settingData.shares_trade_shipping_fee" class="flow_text1" style="color:#07C160">已完成</div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.contract_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.contract_state>=1">{{tableData.trade.sales_pay_at}}</div>
        </div>

        <div v-if="index==8">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.sales_pay_at||(tableData.trade.receipt_state==1&&!settingData.shares_trade_shipping_fee)?'color:#FF5E5E':'#ABABAB'">{{item.title}}</div>
            <img class="flow_imgBtn" v-if="tableData.trade.change_state==1" src="../../assets/successIcon2.png" />
            <img class="flow_imgBtn" v-if="tableData.trade.remit_state==2" src="../../assets/failIcon.png" />
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:block" v-if="tableData.trade.sales_pay_at&&tableData.trade.change_state==0||(tableData.trade.receipt_state==1&&!settingData.shares_trade_shipping_fee)">
            {{item.applyText}}
          </div>
          <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.change_state>=1">{{item.applySuccessText}}</div>
          <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.change_state>=1">{{tableData.trade.contract_info_at}}</div>
        </div>

        <div v-if="index==9">
          <div style="display:flex">
            <div class="flow_title" :style="tableData.trade.change_state>=1?'color:#FF5E5E':'#ABABAB'">{{item.title}}</div>
            <div v-if="tableData.trade">
              <img class="flow_imgBtn" v-if="tableData.trade.sales_shipments.length>1" src="../../assets/successIcon2.png" />
            </div>
            
          </div>
          <div class="flow_text1" style="color:#2E2E30;display:block" v-if="tableData.trade.change_state>=1&&tableData.trade.sales_shipments.length==1">
            {{item.applyText}}
          </div>
          <div v-if="tableData.trade">
              <div class="flow_text1" style="color:#07C160" v-if="tableData.trade.sales_shipments.length>1">{{item.applySuccessText}}<span @click="logistics1(1)" style="color:#0F87FF;margin-left:10px">查看详情></span></div>
              <div class="flow_text1" style="color:#2E2E30" v-if="tableData.trade.sales_shipments.length>1">{{tableData.trade.contract_info_at}}</div>
          </div>
          
        </div>

      </div>
    </div>
    <div class="flow_popbox" v-if="popShow">
      <div v-if="popShow3&&tableData.trade.sales_shipments.length>=1" class="shipper_box">
        <img @click="submitBtn" class="cloneBtn2" style="width:10px;height:10px;" src="../../assets/cloneBtn2.png" />
        <div class="loginBtn1">
          <div style="text-align:right;width:100px;margin-right:30px">物流单号:</div>
          <div>{{dataList.LogisticCode}}</div>
        </div>
        <div class="loginBtn1">
          <div style="text-align:right;width:100px;margin-right:30px">收件人:</div>
          <div>{{tableData.trade.sales_shipments[0].shipments_info.name}}</div>
        </div>
        <div class="loginBtn1">
          <div style="text-align:right;width:100px;margin-right:30px">收件人地址:</div>
          <div>{{tableData.trade.sales_shipments[0].shipments_info.address}}</div>
        </div>
        <div class="steps1" style="color:#000" v-if="dataList.State==3">
          <div>
            <div style="margin-left:-10px" class="boxcolor3">收</div>
            <div class="linecolor1"></div>
          </div>
          <div class="neth">
            <div style="color:#00000080">[收货地址]{{dataList.Location}}</div>
          </div>
        </div>
        <div class="steps1" v-for="(item,index) in dataList.Traces" v-bind:key="index">
          <div v-if="index!=0">
            <div class="boxcolor2"></div>
            <div class="linecolor1"></div>
          </div>
          <div v-else>
            <div class="boxcolor1"></div>
            <div class="linecolor1"></div>
          </div>
          <div :class="index==0&&item.Action!=3?'neth colorText':'neth'">
            <div v-if="item.Action==1">以揽件{{item.AcceptTime}}</div>
            <div v-if="item.Action==2||item.Action==201">运输中{{item.AcceptTime}}</div>
            <div v-if="item.Action==202">派送中{{item.AcceptTime}}</div>
            <div v-if="item.Action==211">待取件{{item.AcceptTime}}</div>
            <div v-if="item.Action==3">已签收{{item.AcceptTime}}</div>
            <div>{{item.AcceptStation}}</div>
          </div>
        </div>
        <div class="steps1" v-if="dataList.State==2">
          <div>
            <div :class="dataList.State==0?'boxcolor1':'boxcolor2'"></div>
            <div class="linecolor1"></div>
          </div>
        </div>
        <div class="steps1">
          <div>
            <div class="boxcolor2"></div>
          </div>
          <div class="neth">
            <div>已下单{{dataList.pay_at}}</div>

          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <!-- 提示框 -->
    <toast v-if="toastShow" :config="configData"></toast>
  </div>
</template>
<script type="text/javascript">
import headerTab from "../../components/headerTab.vue";
import * as apiCommon from "@/api/common";
import {
  getShipments_api,
  shipments_api,
  deliverShipments_api,
  // gettradeShipmentsList_api
} from "@/api/admin";
import { Toast } from "vant";
import { Dialog } from "vant";
import { cancelSale_api, getConfig_api } from "@/api/deal";
import toast from "@/components/toast";
// import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      dataForm: {
        company: {
          value: "",
          title: "快递公司",
          text: "请输入快递公司",
          color: "#DEB77A"
        },
        order_sn: {
          value: "",
          title: "快递单号",
          text: "请输入快递单号",
          color: "#DEB77A"
        },
        message: {
          value: "",
          title: "备注",
          text: "请输入备注",
          color: "#DEB77A"
        }
      },
      popShow: false,
      popShow1: false,
      popShow2: false,
      popShow3: false,
      tableData: {
        info_state: "",
        info_remark: [],
        audit_remark: [],
        trade: {
          sales_shipments: [],
          audit_state: ""
        }
      },
      dataList: null,
      queryList: {},
      companyCode: "",
      toastShow: false,
      index: 0,
      listShow: false, //流程隐藏
      messageList: [
        {
          title: "订单确认",
          applyText: "请耐心等待，保持通话畅通",
          applySuccessText: "转让意向确认",
          itemShow: true
        },
        {
          title: "信息确认",
          applyText: "请核对资料，签署协议。",
          applySuccessText: "信息确认完毕",
          itemShow: true
        },
        {
          title: "受让确认",
          applyText: "等待受让方确认信息！",
          applySuccessText: "受让方确认信息已完成！",
          itemShow: true
        },
        {
          title: "合同回寄",
          applyText: "请回寄大于等于转让数额的工分赠与合同",
          applySuccessText: "合同已完成邮寄",
          itemShow: true
        },
        {
          title: "转让审核",
          applyText: "等待平台审核合同信息！",
          applySuccessText: "合同审核通过",
          itemShow: true
        },
        {
          title: "转让收款",
          applyText: "等待受让方支付款项！",
          applySuccessText: "受让方已完成支付",
          itemShow: false
        },
        {
          title: "款项确认",
          applyText: "",
          applySuccessText: "确认收款",
          itemShow: false
        },
        {
          title: "支付运费",
          applyText: "等待支付物料邮寄费用",
          applySuccessText: "",
          itemShow: false
        },
        {
          title: "信息变更",
          applyText: "等待平台处理，生成合同/用户数据！",
          applySuccessText: "处理完成，合同数据已生成",
          itemShow: false
        },
        {
          title: "物料邮寄",
          applyText: "等待物流发货，请关注提示！",
          applySuccessText: "已发货",
          itemShow: false
        }
      ],
      settingData: "",
      step: ""
    };
  },
  components: {
    toast,
    headerTab
  },
  created() {
    document.title = "订单详情";
    this.queryList = this.$route.query;
    // if(this.queryList.audit_id){
    this.getDetails(this.queryList.id);
    // 获取配置
    this.getConfig();
    // }
  },
  methods: {
    getConfig() {
      let data = {
        config: ["shares_trade_shipping_fee"]
      };
      getConfig_api(data).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.settingData = res.data;
          this.settingData.shares_trade_shipping_fee = Number(this.settingData.shares_trade_shipping_fee)
          this.$forceUpdate();
        }
      });
    },
    // 确认收款
    submitPay(index, status) {
      if (index == 1) {
        if (status == 1) {
          this.$router.push(
            `./moneyConfirm?index=${this.tableData.trade.trade_id}&id=${
              this.tableData.sales_id
            }&status=1`
          );
        } else {
          this.$router.push(`./abnormal?id=${this.tableData.trade.trade_id}`);
        }
      }else if(index==2){
           this.$router.push(
          `./moneyConfirm?index=${this.tableData.trade.trade_id}&id=${
            this.tableData.sales_id
          }&look=true`
        );
      }else {
        this.$router.push(
          `./moneyConfirm?index=${this.tableData.trade.trade_id}&id=${
            this.tableData.sales_id
          }`
        );
      }
      console.log(1);
    },
    lookMore(index) {
      // 下拉查看更多
      // 关闭
      if (index == 1) {
        if (this.step > 4) {
          this.messageList.forEach((item, index) => {
            if (index > 3) {
              item.itemShow = true;
            } else {
              item.itemShow = false;
            }
            // if (index == 9) {
            //   item.itemShow = false;
            // }
          });
        } else {
          this.messageList.forEach((item, index) => {
            if (index <= 3) {
              item.itemShow = true;
            } else {
              item.itemShow = false;
            }
          });
        }
      } else {
        // 展开
        this.messageList.forEach(e => {
          e.itemShow = true;
        });
      }
      console.log(this.messageList)
      this.$forceUpdate();
    },
    cloneBtn() {
      Dialog.confirm({
        title: "提示",
        message: "确定删除订单"
      })
        .then(() => {
          cancelSale_api(this.queryList.id).then(res => {
            if (res.code == 0) {
              this.toastShow = true;
              this.configData.title = "操作成功";
              setTimeout(() => {
                this.toastShow = false;
                this.$router.go(-1);
              }, 1000);
            }
          });
        })
        .catch(() => {
          console.log(1);
        });
    },
    lookBtns() {
      this.$router.push(`./payInput?id=${this.tableData.sales_id}`);
    },
    // 信息确认
    messageBtn() {
      this.$router.push(`./messages?id=${this.tableData.trade_id}`);
    },
    mineMessage() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
    },
    detailsBtn() {
      this.$router.push("../contract");
    },
    // test() {
    //   let data = {
    //     LogisticCode: this.dataForm.order_sn
    //   };
    //   gettradeShipmentsList_api(data).then(res => {
    //     if (res.code == 0) {
    //       this.companyCode = res.code;
    //     }
    //   });
    // },
    async logistics(index) {
      this.tableData.trade.sales_shipments[index].shipments_info.logistic = 0;
      await getShipments_api(
        this.tableData.trade.sales_shipments[index].shipments_info
      ).then(res => {
        console.log(res.data);
        if (res.code == 0) {
          this.dataList = res.data;
          if (this.dataList) {
            this.dataList.Traces.reverse();
          }
          if (this.dataList.Traces.length == 0) {
            Toast({
              message: "暂无物流信息",
              icon: "none",
              duration: 1000
            });
          } else {
            this.popShow = true;
            this.popShow1 = true;
            this.popShow3 = true;
          }
          this.$forceUpdate();
        }
      });
    },
    submitBtn() {
      this.popShow = false;
      this.popShow2 = false;
      this.popShow1 = false;
      this.popShow3 = false;
    },
    logistics1(index) {
      if (index == 0) {
        if (
          this.tableData.trade.sales_shipments.length > 0 &&
          this.tableData.trade.sales_shipments[index].shipments_info
        ) {
          this.logistics(index);
        } else {
          Toast({
            message: "暂无物流信息",
            icon: "success",
            duration: 1000
          });
          return false;
        }
      }
      if (index == 1) {
        if (
          this.tableData.trade.sales_shipments.length > 1 &&
          this.tableData.trade.sales_shipments[index].shipments_info
        ) {
          this.logistics(index);
        } else {
          Toast({
            message: "暂无物流信息",
            icon: "success",
            duration: 1000
          });
          return false;
        }
      }
      this.index = index;

      this.$forceUpdate();
    },
    lookAddress() {
      this.popShow = true;
      this.popShow3 = true;
      this.popShow1 = true;
    },
    returnContract() {
      this.$router.push(`./payInput?id=${this.queryList.id}`);
    },
    lookBtn() {
      if (this.queryList.status) {
        this.$router.push("./index");
      } else {
        this.$router.push(`./index?id=${this.tableData.audit_id}`);
      }
    },
    getOrder() {
      this.tableData.trade.sales_shipments[0].tradeShipments_info.logistic = 0;
      deliverShipments_api(
        this.tableData.trade.sales_shipments[0].tradeShipments_id
      ).then(res => {
        if (res.code == 0) {
          this.popShow = false;
          this.popShow3 = false;
          this.getDetails(this.queryList.audit_id);
        }
      });
    },
    companyBtn() {
      for (var i in this.dataForm) {
        if (!this.dataForm[i]) {
          return false;
        }
      }
      let data = {
        audit_id: this.tableData.trade.trade_id,
        audit_type: 4,
        shipments_info: {
          company: this.dataForm.company.value,
          order_sn: this.dataForm.order_sn.value,
          message: this.dataForm.message.value
        }
      };
      console.log(data, this.queryList, "data");
      shipments_api(data).then(res => {
        if (res.code == 0) {
          this.popShow = false;
          this.popShow2 = false;
          this.popShow1 = false;
          this.popShow3 = false;
          this.getDetails(this.queryList.id);
        }
      });
    },
    async getDetails(id) {
      let res = await apiCommon.SellingDeal_api(id);
      if (res.code == 0) {
        this.tableData = res.data;
        if (this.tableData.trade_state == 0) {
          this.$router.go(-1);
        }
        if (!this.tableData.trade) {
          this.step = 1;
        }
        if (this.tableData.trade) {
          if (
            this.tableData.trade.audit_state > 0 &&
            this.tableData.trade.sales_info_state == 0
          ) {
            this.step = 2;
          }
          if (
            this.tableData.trade.sales_info_state > 0 &&
            this.tableData.trade.payment_state == 0
          ) {
            this.step = 3;
          }
          if (
            this.tableData.trade.payment_state > 0 &&
            this.tableData.trade.return_state == 0
          ) {
            this.step = 4;
          }
          if (
            this.tableData.trade.return_state > 0 &&
            this.tableData.trade.contract_state == 0
          ) {
            this.step = 5;
          }
          if (
            this.tableData.trade.contract_state > 0 &&
            this.tableData.trade.remit_state == 0
          ) {
            this.step = 6;
          }
          if (
            (this.tableData.trade.remit_state > 0||this.tableData.trade.receipt_state==3) &&
            this.tableData.trade.receipt_state != 1
          ) {
            this.step = 7;
          }
          if (
            this.tableData.trade.receipt_state == 1 &&
            !this.tableData.trade.sales_pay_at
          ) {
            this.step = 8;
          }
          if (
            this.tableData.trade.sales_pay_at &&
            this.tableData.trade.change_state == 0||(this.tableData.trade.receipt_state==1&&!this.settingData.shares_trade_shipping_fee)
          ) {
            this.step = 9;
          }
          if (
            this.tableData.trade.change_state > 0 &&
            this.tableData.trade.sales_shipments.length >= 0
          ) {
            this.step = 10;
          }
        }

        if (this.step > 4) {
          this.messageList.forEach((item, index) => {
            if (index > 3) {
              item.itemShow = true;
            } else {
              item.itemShow = false;
            }
            // if (index == 9) {
            //   item.itemShow = false;
            // }
          });
        } else {
          this.messageList.forEach((item, index) => {
            if (index <= 3) {
              item.itemShow = true;
            } else {
              item.itemShow = false;
            }
          });
        }
        if(!this.tableData.trade){
          this.tableData.trade = {};
        this.tableData.trade.sales_shipments = []
        }
        
        console.log(this.step, "111", this.messageList);
        this.$forceUpdate();
      }
    },
    payBtn(index) {
      if (index == 3) {
        this.$router.push(
          `./freight?id=${this.tableData.trade.trade_id}&status=2&freight=${
            this.settingData.shares_trade_shipping_fee
          }`
        );
      } else {
        this.$router.push(`./pay?id=${this.tableData.trade_id}`);
      }
    },
    checkBtn() {
      this.$router.push(
        `./confirmation?id=${this.tableData.trade.sales_id}&index=${
          this.tableData.trade.trade_id
        }`
      );
    }
  }
};
</script>
<style>
.item_btn {
  display: flex;
  margin-top: 20px
}
.item_btn div {
  width: 230px;
  height: 64px;
  background: #ff3e46;
  box-shadow: 3px 3px 10px rgba(255, 62, 70, 0.35);
  opacity: 1;
  color: #fff;
  text-align: center;
  line-height: 64px;
  font-size: 25px;
  border-radius: 30px;
  margin-right: 20px;
}
.lookMore {
  width: 619px;
  height: 50px;
  background: #ffffff;
  border: 2px solid #0f87ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 30px;
  color: #0f87ff;
  line-height: 50px;
  text-align: center;
  margin: 0 auto 30px;
}
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
.cloneBtn {
  background: #deb77a;
  width: 160px;
  height: 51px;
  color: #fff;
  font-size: 25px;
  border-radius: 20px;
  text-align: center;
  line-height: 51px;
  margin-left: 500px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.receiver div {
  margin-bottom: 20px;
  color: #707070ff;
}
.time_text {
  font-size: 20px;
  color: #000;
  margin: 10px 0 20px 10px;
}
.pop3 {
  height: 776px;
  background: #ffffff;
  font-size: 16px;
  color: #707070;
  padding: 20px 20px 20px 50px;
}
.payClass {
  color: #4b93ff;
  font-size: 25px;
  margin-left: 10px;
}
.shipper_box {
  width: 700px;
  height: 800px;
  background-color: #fff;
  margin: 200px auto 0;
  font-size: 20px;
  color: #00000080;
  overflow-y: scroll;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}
.flow_text {
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #deb77a;
  text-align: right;
  margin: 25px 0;
}
.flow_contanter {
  padding: 0 38px;
  box-sizing: border-box;
}
.userBtn {
  float: right;
  text-align: center;
}
.userBtn img {
  width: 36px;
  height: 36px;
}
.userBtn div {
  font-size: 15px;
  color: #000;
}
.flow_box {
  width: 37px;
  height: 37px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 37px;
  color: #737373;
  opacity: 1;
  text-align: center;
  border: 2px solid #737373;
  border-radius: 50%;
}
.flow_content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.line_flow {
  width: 1px;
  border: 1px dashed #737373;
  margin: 0 18px;
  height: 150px;
}
.flow_list {
  display: flex;
  padding: 30px auto;
}
.flow_imgBtn {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin: auto 0;
}
.flow_title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(115, 115, 115, 0.5);
  margin: 0 10px;
}
.flow_text1 {
  margin-left: 10px;
  font-size: 25px;
  line-height: 40px;
  color: #4b93ff;
  margin-top: 15px;
  display: flex;
}
.flow_text1 span {
  color: #0f87ff;
  margin-left: 10px;
}
.flow_popbox {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 30px;
}
.flow_pop1 {
  width: 600px;
  /* height:700px; */
  background-color: #fff;
  margin: 200px auto 0;
  /* text-align:right; */
  padding: 100px 20px 20px;
  box-sizing: border-box;
  position: relative;
}
.flow_pop1 img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.pop1 {
  font-size: 22px;
}
.pop1 > div {
  margin: 20px 60px;
  text-align: none;
}
.title_pop {
  width: 130px;
  display: inline-block;
  color: #707070ff;
}
.pop3 > div {
  margin-top: 20px;
}
.formList_flow {
  display: flex;
}
.offine_title_flow {
  width: 100px;
  font-size: 22px;
  color: #707070;
  margin: 0;
}
.pop1 .submitBtn {
  width: 160px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  border: 1px solid #ff5e5e;
  color: #ff5e5e;
  margin: 20px auto 0;
}
.address_text_flow {
  width: 300px;
}
.pop2 > div {
  font-size: 26px;
  color: #707070;
  margin: 20px 0;
}
.pop2 {
  margin-left: 80px;
}
.selectColor {
  color: #ff5e5e;
  border: 1px solid #ff5e5e;
}
.selectLine {
  border: 1px dashed #ff5e5e;
}
@step-icon-size {
  font-size: 40px;
}
.title_text {
  color: #4b93ff;
  margin: 0px 0 0 20px !important;
  font-size: 24px;
}
.card {
  margin: 20px 0;
}
.success2 {
  width: 24px;
  height: 24px;
  margin: 10px 0 0 5px;
}
.messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle1 {
  text-align: right;
  margin-right: 20px;
}
.loginBtn1 {
  margin: 20px 0;
  display: flex;
  /* margin-left: 71px */
}
.loginBtn1 > div {
  /* display: flex; */
  /* margin-left: 77px; */
}
.loginBtn1 span {
  margin: 0 5px;
}
.comfigBtn {
  width: 124px;
  height: 40px;
  background-color: #4b93ff;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  margin-left: 79px;
}
.getAddress {
  display: flex;
  margin-left: 70px;
  margin-top: 30px;
}
.steps1 {
  /* height: 200px; */
  display: flex;
  margin-left: 60px;
}
.steps1 .colorText {
  color: #000000;
}
.steps1 .neth div {
  margin: 5px 0;
}
.neth {
  padding: 5px 0 20px 10px;
}
.linecolor {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px dashed #ff5e5e;
  margin-left: 18.5px;
}
.linecolor1 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.linecolor2 {
  width: 1px;
  height: 100%;
  opacity: 1;
  border: 1px solid #d1d1d1;
  margin-left: 6.5px;
}
.boxcolor {
  width: 37px;
  height: 37px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  border-radius: 50%;
  opacity: 1;
  text-align: center;
  line-height: 37px;
  color: #ff5e5e;
  font-size: 22px;
}
.boxcolor1 {
  width: 13px;
  height: 13px;
  background: #ff5e5e;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor2 {
  width: 13px;
  height: 13px;
  background: #d1d1d1;
  border-radius: 50%;
  opacity: 1;
}
.boxcolor3 {
  width: 44px;
  height: 44px;
  background: #fc6305;
  border-radius: 50%;
  opacity: 1;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}
.cart {
  width: 120px;
  height: 120px;
  margin: 190px auto 0;
}
.cart img {
  width: 100%;
  height: 100%;
}
.auditInfo {
  margin: 50px 0 0 37px;
}
.content1 {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 40px;
  box-sizing: border-box;
  width: 100%;
}
.steps {
  height: 150px;
  padding-left: 61px;
}
.step_text {
  font-size: 22px;
  margin: 52px 0 0 54px;
}
.van-step--vertical {
  font-size: 22px;
  color: #000000;
}
.van-step--vertical {
  /* height: 200px; */
  line-height: 45px;
}
.title1 {
  color: #ff5e5e;
}
.van-step__icon--active,
.van-step__icon--finish,
.van-step__title--active,
.van-step__title--finish {
  color: #ff5e5e;
}
.submit {
  width: 160px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #4b93ff;
  opacity: 1;
  border-radius: 5px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
  margin-left: 100px;
  margin-top: 20px;
}
.logistics {
  margin-left: 70px;
}
.cloneBtn2 {
  position: absolute;
  top: 20px;
  right: 20px;
}
.order {
  width: 141px;
  height: 51px;
  background: #ffffff;
  border: 2px solid #ff5e5e;
  opacity: 1;
  border-radius: 5px;
  text-align: center;
  line-height: 51px;
  font-size: 25px;
  color: #ff5e5e;
  margin-left: 70px;
}
.check {
  width: 126px;
  height: 40px;
  background: #07c160;
  opacity: 1;
  border-radius: 20px;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  margin-left: 10px;
  padding: 1px 10px
}
.check img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}
.flow_header .bg_line {
  color: #ababab;
  border: 2px solid #ababab;
}
.flow_header .bs_line {
  color: #ff5e5e;
  border: 2px solid #ff5e5e;
}
.bg {
  color: #ababab;
}
.bc {
  color: #ff5e5e;
}
</style>